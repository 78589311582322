import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    Button,
    CircularProgress,
} from "@material-ui/core"
import React, { useState } from "react"
import { Session } from "../types"
import CancelIcon from "@material-ui/icons/Cancel"
import * as api from "../lib/api"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
dayjs.extend(relativeTime)

const SessionsTable: React.FC<{
    sessions: Session[]
    onSessionRemoved: () => void
}> = ({ sessions, onSessionRemoved }) => {
    const [deletingSession, setDeletingSession] = useState(false)

    if (sessions.length <= 0) {
        return <></>
    }

    const deleteSession = async (sessionId: string) => {
        setDeletingSession(true)
        await api
            .deleteSession(sessionId)
            .finally(() => setDeletingSession(false))

        onSessionRemoved()
    }

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Zone ID</TableCell>
                        <TableCell>Started</TableCell>
                        <TableCell>Parking Time</TableCell>
                        <TableCell>Vehicle</TableCell>
                        <TableCell
                            align="right"
                            style={{ textAlign: "center" }}
                        >
                            {deletingSession ? (
                                <CircularProgress
                                    color="secondary"
                                    style={{
                                        height: "16px",
                                        width: "16px",
                                        padding: 0,
                                    }}
                                />
                            ) : (
                                <>Actions</>
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {[...sessions]
                        .sort((a, b) =>
                            a.createdTimestamp > b.createdTimestamp ? -1 : 1
                        )
                        .map((session) => (
                            <TableRow key={session.sessionId}>
                                <TableCell>{session.zoneId}</TableCell>
                                <TableCell>
                                    {dayjs(session.createdTimestamp).format(
                                        "dddd @ HH:mm - YYYY/MM/DD"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {" "}
                                    {dayjs(session.createdTimestamp).toNow(
                                        true
                                    )}{" "}
                                </TableCell>
                                <TableCell>{session.numberPlate}</TableCell>
                                <TableCell
                                    align="right"
                                    style={{
                                        textAlign: "center",
                                    }}
                                >
                                    <Tooltip
                                        title="End parking session"
                                        enterDelay={1000}
                                        enterNextDelay={2000}
                                    >
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() =>
                                                deleteSession(session.sessionId)
                                            }
                                            style={{ marginLeft: "10px" }}
                                        >
                                            <CancelIcon fontSize="small" />
                                        </Button>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SessionsTable
