import Amplify from "aws-amplify"

export async function userAuthorized(): Promise<boolean> {
    const token = await getAccessToken()
    return token !== undefined
}

export async function getAccessToken(): Promise<string | undefined> {
    try {
        const session = await Amplify.Auth.currentSession()
        return session.accessToken.jwtToken
    } catch (err) {
        // No current user
        return
    }
}
