import { AmplifyAuthenticator } from "@aws-amplify/ui-react"
import Amplify, { Hub } from "aws-amplify"
import React from "react"
import { useEffect } from "react"
import { useSetRecoilState } from "recoil"
import * as auth from "../lib/auth"
import { userAuthenticatedState } from "../lib/state"

// https://docs.amplify.aws/lib/auth/start/q/platform/js#re-use-existing-authentication-resource
Amplify.configure({
    Auth: {
        region: process.env.REACT_APP_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        mandatorySignIn: false,
    },
})

const Auth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const setAuthenticated = useSetRecoilState(userAuthenticatedState)

    useEffect(() => {
        auth.userAuthorized().then(setAuthenticated)
    }, [setAuthenticated])

    const listener = (data: any) => {
        switch (data.payload.event) {
            case "signIn":
            case "signUp":
                setAuthenticated(true)
        }
    }

    Hub.listen("auth", listener)
    return <AmplifyAuthenticator>{children}</AmplifyAuthenticator>
}

export default Auth
