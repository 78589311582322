import React, { useState } from "react"
import { useEffect } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { verifyUser } from "../lib/api"
import { userAuthenticatedState, userVerifiedState } from "../lib/state"
import UserSettings from "./UserSettings"
import * as E from "fp-ts/Either"
import { CircularProgress } from "@material-ui/core"
import { useCallback } from "react"
import styled from "styled-components"
import { Breakpoints } from "../lib/breakpoints"

const Verifying = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: ${Breakpoints.mobile}) {
        flex-direction: column;
    }
`

/**
 * Component for the signup flow.
 * - Signup involves setting a valid AT username/password combo.
 */
const Signup: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const authenticated = useRecoilValue(userAuthenticatedState)
    const [verified, setVerified] = useRecoilState(userVerifiedState)
    const [verifying, setVerifying] = useState(false)

    const verify = useCallback(() => {
        if (!authenticated) {
            return
        }

        setVerifying(true)

        // Use the API to verify the user has a valid AT username/password set
        verifyUser()
            .then((result) => {
                if (E.isRight(result)) {
                    setVerified(true)
                }
            })
            .finally(() => {
                setVerifying(false)
            })
    }, [authenticated, setVerified])

    useEffect(() => {
        verify()
    }, [verify])

    if (!authenticated) {
        return <></>
    }

    if (verifying) {
        return (
            <Verifying>
                <h2>Verifying account...</h2>
                <CircularProgress />
            </Verifying>
        )
    }

    if (!verified) {
        return (
            <UserSettings
                onSave={() => {
                    verify()
                }}
            />
        )
    }

    return <div>{children}</div>
}

export default Signup
