import { Session } from "../types"
import { atom } from "recoil"

export const userVerifiedState = atom<boolean>({
    key: "userVerified",
    default: false,
})

export const userAuthenticatedState = atom<boolean>({
    key: "userAuthenticated",
    default: false,
})

export const parkingSessionsState = atom<Session[]>({
    key: "parkingSessions",
    default: [],
})

export const vehiclesState = atom<string[]>({
    key: "vehicles",
    default: [],
})
