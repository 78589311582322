import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import App from "./components/App"
import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "@material-ui/core"
import { RecoilRoot } from "recoil"
import { defaultTheme } from "./lib/themes"

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <ThemeProvider theme={defaultTheme}>
                <App />
            </ThemeProvider>
        </RecoilRoot>
    </React.StrictMode>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
