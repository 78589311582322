import { Button, CircularProgress, TextField } from "@material-ui/core"
import React, { useState } from "react"
import * as api from "../lib/api"
import styled from "styled-components"
import LoadingButton from "./LoadingButton"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
`

const Header = styled.h2`
    margin: 0;
`

const ButtonWrapper = styled.div`
    button {
        height: 50px;
        width: 100px;
    }
`

const Inputs = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
`

const UserSettings: React.FC<{ onSave: () => void }> = (props) => {
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const saveUsernamePassword = async () => {
        setLoading(true)
        await api.saveUsernamePassword(username, password).finally(() => {
            setLoading(false)
            props.onSave()
        })
    }

    return (
        <Wrapper>
            <Header>Connect your AT account</Header>
            <p>
                These credentials should be the same credentials used to login
                to the official AT parking app (
                <a
                    href="https://atpark.at.govt.nz/user/parking"
                    target="_blank"
                    rel="noreferrer"
                >
                    https://atpark.at.govt.nz/user/parking
                </a>
                ).
            </p>
            <Inputs>
                <TextField
                    id="username"
                    label="Username"
                    variant="outlined"
                    disabled={loading}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    id="password"
                    label="Password"
                    variant="outlined"
                    type="password"
                    disabled={loading}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <LoadingButton
                    onClick={saveUsernamePassword}
                    label="save"
                    loading={loading}
                    disabled={
                        loading ||
                        username.trim() === "" ||
                        password.trim() === "" ||
                        username === undefined ||
                        password === undefined
                    }
                />
            </Inputs>
        </Wrapper>
    )
}

export default UserSettings
