import { Button, CircularProgress } from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"
import { useRecoilState } from "recoil"
import styled from "styled-components"
import * as api from "../lib/api"
import { parkingSessionsState } from "../lib/state"
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import NewSessionModal from "./NewSessionModal"
import SessionsTable from "./SessionsTable"
import { Breakpoints } from "../lib/breakpoints"
dayjs.extend(relativeTime)

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: ${Breakpoints.mobile}) {
        padding: 0 40px;
    }
`

const Title = styled.h2`
    margin: 0;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: ${Breakpoints.mobile}) {
        flex-direction: column;
        gap: 40px;
    }
`

const ButtonWrapper = styled.div`
    @media screen and (max-width: ${Breakpoints.mobile}) {
        width: 100%;
        button {
            height: 50px;
            width: 100%;
        }
    }

    button {
        height: 50px;
    }
`

const Sessions: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [showNewSessionModal, setShowNewSessionModal] = useState(false)
    const [parkingSessions, setParkingSessions] =
        useRecoilState(parkingSessionsState)

    const fetchParkingSessions = () => {
        setLoading(true)
        api.getParkingSessions()
            .then(setParkingSessions)
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchParkingSessions()

        const timer = setInterval(() => {
            api.getParkingSessions().then(setParkingSessions)
        }, 30000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    const activeParkingSessions = [...parkingSessions].filter(
        (session) => session.active === 1
    )

    return (
        <Wrapper>
            <Header>
                {loading ? (
                    <>
                        <Title>Loading your parking sessions</Title>
                        <CircularProgress />
                    </>
                ) : (
                    <>
                        <Title>
                            {activeParkingSessions.length > 0
                                ? "Your Parking Sessions"
                                : "No active parking sessions"}
                        </Title>
                        <ButtonWrapper>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setShowNewSessionModal(true)}
                            >
                                Start a new parking session
                            </Button>
                        </ButtonWrapper>
                    </>
                )}
            </Header>
            <SessionsTable
                sessions={activeParkingSessions}
                onSessionRemoved={fetchParkingSessions}
            />
            <NewSessionModal
                show={showNewSessionModal}
                onClose={() => {
                    setShowNewSessionModal(false)
                    fetchParkingSessions()
                }}
            />
        </Wrapper>
    )
}

export default Sessions
