import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { getAccessToken } from "../lib/auth"
import { userAuthenticatedState } from "../lib/state"
import Auth from "./Auth"
import Sessions from "./Sessions"
import Signup from "./Signup"
import Navbar from "./Navbar"
import styled from "styled-components"

const Wrapper = styled.div`
    margin-top: 130px;
    text-align: center;
`

const App: React.FC = () => {
    const authenticated = useRecoilValue(userAuthenticatedState)
    const [accessToken, setAccessToken] = useState<string>()

    useEffect(() => {
        if (authenticated) {
            getAccessToken().then(setAccessToken)
        }
    }, [authenticated])

    return (
        <Auth>
            <Navbar />
            <Wrapper>
                <Signup>{accessToken ? <Sessions /> : <></>}</Signup>
            </Wrapper>
        </Auth>
    )
}

export default App
