import { createTheme, ThemeOptions } from "@material-ui/core"

// https://coolors.co/157a6e-499f68-77b28c-c2c5bb-b4654a
export const defaultTheme = createTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#499F68",
        },
        secondary: {
            main: "#bb6444",
        },
        background: {
            default: "#000000",
        },
        error: {
            main: "#d05023",
        },
        info: {
            main: "#77B28C",
        },
        success: {
            main: "#157A6E",
        },
        text: {
            primary: "#ffffff",
        },
    },
    typography: { fontFamily: ["Fira Sans", "sans-serif"].join(",") },
})
