import axios from "axios"
import { ErrorCode, Session } from "../types"

import { getAccessToken } from "./auth"
import * as E from "fp-ts/Either"

/**
 * Save the user's AT username/password.
 */
export async function saveUsernamePassword(
    username: string,
    password: string
): Promise<void> {
    const token = await getAccessToken()

    if (token) {
        await axios.put(
            `${process.env.REACT_APP_API_ENDPOINT}/users`,
            undefined,
            {
                headers: { Authorization: token },
                params: { username, password },
            }
        )
    }
}

/**
 * Get the current AT user.
 */
export async function getUser(): Promise<any> {
    const token = await getAccessToken()

    if (token) {
        return axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/users`, {
                headers: { Authorization: token },
            })
            .then((res) => res.data)
    }
}

/**
 * Verify the current user.
 */
export async function verifyUser(): Promise<
    E.Either<ErrorCode | undefined, true>
> {
    try {
        const token = await getAccessToken()

        if (!token) {
            return E.left(undefined)
        }

        await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/verify-user`, {
            headers: { Authorization: token },
        })

        return E.right(true)
    } catch (err) {
        return E.left(err.response.data[0] as ErrorCode)
    }
}

export async function getParkingSessions(): Promise<Session[]> {
    const token = await getAccessToken()

    if (!token) {
        return []
    }

    const sessions = await axios.get<Session[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/sessions`,
        {
            headers: { Authorization: token },
        }
    )

    return sessions.data
}

export async function getVehicles(): Promise<string[]> {
    const token = await getAccessToken()

    if (!token) {
        return []
    }

    const sessions = await axios.get<string[]>(
        `${process.env.REACT_APP_API_ENDPOINT}/vehicles`,
        {
            headers: { Authorization: token },
        }
    )

    return sessions.data
}

export async function createSession(
    zoneId: string,
    numberPlate: string
): Promise<E.Either<ErrorCode | undefined, true>> {
    const token = await getAccessToken()

    if (!token) {
        return E.left(undefined)
    }

    try {
        await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/sessions`,
            { zoneId, numberPlate },
            {
                headers: { Authorization: token },
            }
        )

        return E.right(true)
    } catch (err) {
        return E.left(err.response.data[0] as ErrorCode)
    }
}

export async function deleteSession(sessionId: string): Promise<void> {
    const token = await getAccessToken()

    if (token) {
        await axios.delete(
            `${process.env.REACT_APP_API_ENDPOINT}/sessions/${sessionId}`,
            {
                headers: { Authorization: token },
            }
        )
    }
}
