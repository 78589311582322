import { Button, CircularProgress } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    button {
        height: 50px;
        width: 100%;
        min-width: 100px;
        padding: 30px 0;
    }
`

const LoadingButton: React.FC<{
    label: string
    onClick: () => void
    disabled: boolean
    loading: boolean
}> = ({ label, onClick, disabled, loading }) => {
    return (
        <Wrapper>
            <Button
                variant="contained"
                color="primary"
                onClick={onClick}
                disabled={disabled}
            >
                {loading ? <CircularProgress /> : <>{label}</>}
            </Button>
        </Wrapper>
    )
}

export default LoadingButton
