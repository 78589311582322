import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    top: 0;
    left: 0;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid #3f3f3f;
    position: fixed;
    background-color: #111111;
`

const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0 25px;
`

const Title = styled.h4`
    margin: 10px 0;
    font-weight: 800;
    letter-spacing: 0.05rem;

    a {
        text-decoration: none;
        color: white;
        text-shadow: 1px 1px 5px black;
    }
`

const Navbar: React.FC = () => {
    return (
        <Wrapper>
            <Content>
                <Title>
                    <a href="https://atparking.co.nz">
                        Auckland Parking 🚗 🛣️ 🚧
                    </a>
                </Title>
            </Content>
        </Wrapper>
    )
}

export default Navbar
