import {
    Backdrop,
    CircularProgress,
    Fade,
    Modal,
    TextField,
} from "@material-ui/core"
import React, { useState } from "react"
import { useEffect } from "react"
import * as api from "../lib/api"
import styled from "styled-components"
import { vehiclesState } from "../lib/state"
import { useRecoilState } from "recoil"
import { Alert, Autocomplete } from "@material-ui/lab"
import LoadingButton from "./LoadingButton"
import * as E from "fp-ts/Either"
import { Breakpoints } from "../lib/breakpoints"
import { ErrorCode } from "../types"

const Wrapper = styled.div``

const ModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    gap: 40px;
    margin-top: 40px;
    width: 50%;

    @media (max-width: ${Breakpoints.mobile}) {
        max-width: 100%;
        padding: 0 40px;
    }
`

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;

    @media (max-width: ${Breakpoints.mobile}) {
        flex-direction: column;
    }
`

const Header = styled.h2`
    text-align: center;
    margin: 0;
`

const NewSession: React.FC<{
    show: boolean
    onClose: (newSessionAdded?: boolean) => void
}> = ({ show, onClose }) => {
    const [zoneId, setZoneId] = useState<string>()
    const [loadingVehicles, setLoadingVehicles] = useState(false)
    const [creatingSession, setCreatingSession] = useState(false)
    const [vehicles, setVehicles] = useRecoilState(vehiclesState)
    const [numberPlate, setNumberPlate] = useState<string>()
    const [createSessionErrorCode, setCreateSessionErrorCode] =
        useState<ErrorCode>()

    useEffect(() => {
        setLoadingVehicles(true)
        api.getVehicles()
            .then(setVehicles)
            .finally(() => setLoadingVehicles(false))
    }, [setLoadingVehicles, setVehicles])

    const isLoading = () => loadingVehicles || creatingSession

    const startSession = async () => {
        if (
            zoneId &&
            numberPlate &&
            zoneId.trim() !== "" &&
            numberPlate.trim() !== ""
        ) {
            setCreatingSession(true)
            setCreateSessionErrorCode(undefined)

            await api
                .createSession(zoneId, numberPlate)
                .then((error) => {
                    if (E.isLeft(error)) {
                        setCreateSessionErrorCode(error.left)
                    } else {
                        onClose(true)
                    }
                })
                .finally(() => setCreatingSession(false))
        }
    }

    return (
        <Wrapper>
            <Modal
                open={show}
                onClose={() => onClose(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={show}>
                    <ModalWrapper>
                        <HeaderWrapper>
                            {loadingVehicles ? (
                                <>
                                    <Header>Loading vehicles</Header>
                                    <CircularProgress />
                                </>
                            ) : (
                                <Header>Start a new parking session</Header>
                            )}
                        </HeaderWrapper>
                        <TextField
                            id="zone"
                            label="Area ID"
                            variant="outlined"
                            disabled={isLoading()}
                            helperText="The unique 6-digit Area ID of the parking zone. This can be found on the pay by plate machines and parking signs."
                            value={zoneId}
                            onChange={(e) => setZoneId(e.target.value)}
                        />
                        <Autocomplete
                            options={vehicles}
                            getOptionLabel={(option) => option}
                            autoComplete
                            noOptionsText="No vehicles found. Add a vehicle using the AT park app."
                            includeInputInList
                            filterSelectedOptions
                            disabled={isLoading()}
                            onChange={(_: any, newValue: string | null) => {
                                console.log(newValue)
                                if (
                                    typeof newValue !== "string" ||
                                    newValue === null ||
                                    newValue.trim() === ""
                                ) {
                                    return
                                }

                                setNumberPlate(newValue)
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Number plate"
                                    variant="outlined"
                                    helperText="Your vehicle's license plate number. Make sure you have added this in your AT account first."
                                />
                            )}
                        />
                        {createSessionErrorCode ? (
                            <Alert
                                severity={
                                    createSessionErrorCode ===
                                    "PARKING_IS_FREE_RIGHT_NOW"
                                        ? "warning"
                                        : "error"
                                }
                            >
                                {createSessionErrorCode ===
                                "PARKING_IS_FREE_RIGHT_NOW" ? (
                                    <>
                                        It is currently outside of paid parking
                                        hours.
                                    </>
                                ) : (
                                    <>
                                        Sorry, we were unable to create a ticket
                                        at this time.
                                    </>
                                )}
                            </Alert>
                        ) : (
                            <></>
                        )}
                        <LoadingButton
                            onClick={startSession}
                            disabled={
                                isLoading() ||
                                zoneId === undefined ||
                                numberPlate === undefined
                            }
                            label="Start session"
                            loading={creatingSession}
                        />
                    </ModalWrapper>
                </Fade>
            </Modal>
        </Wrapper>
    )
}

export default NewSession
